import "core-js/stable"
import React, { useState, useEffect } from "react"
// import Modal from "../components/modal"
// import MainContext from "../stores/mainContext"
//import { Link } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import SwiperCore, {Keyboard} from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import ReactPlayer from "react-player"
import "swiper/swiper.scss"

SwiperCore.use([Keyboard])

const userAgent = typeof window != 'undefined' ? window.navigator.userAgent.toLowerCase() : '';
const ios = /iphone|ipod|ipad/.test(userAgent);
const safari = /safari/.test(userAgent);

const MySwiper = ({
  gap,
  loop,
  video,
  files,
  video_image,
  publication
}) => {
  // const modalVideo = useRef()
  // const { mainContext, dispatch } = React.useContext(MainContext)
  const [playing, setPlaying] = useState(false)
  const [isVidError, setVidError] = useState(false)
  const [showVideo, setShowVideo] = useState(false)
  const [isIOS, setIOS] = useState(ios || safari)

  
  const playVideo = () => {
    // modalVideo.current.openModal()
    // setTimeout(() => {
    setShowVideo(true)
    setPlaying(!playing)

    // }, 500)
  }
  let bgimages
  if (files && files.length > 0) {
    bgimages = files.map(file => {
      const desc = file.description
      const title = file.title
      file = getImage(file.localFile)
      file.desc = desc
      file.title = title
      return file
    })
  }

  // useEffect(() => {
  //   const userAgent = window.navigator.userAgent.toLowerCase();
  //   const ios = /iphone|ipod|ipad/.test( userAgent );
  //   const safari = /safari/.test( userAgent );
  //   setIOS(ios || safari)
  // }, [])
  
  
  const renderSlide = (item, i) => {
    if (item) {
      return (
        <SwiperSlide key={`bgslide${i}`}>
          <BgImage
            image={item}
            style={{ minWidth: 200, minHeight: 140 }}
          ></BgImage>
          <div className="o-dictate" dangerouslySetInnerHTML={{__html: item.desc}}></div>
        </SwiperSlide>
      )
    }
  }

  return (
    <div className="mySwiper">
      <Swiper
        loop={ios? false : loop}
        slideToClickedSlide={true}
        spaceBetween={gap}
        slidesPerView={1}
        keyboard={{
          "enabled": true
        }}
        breakpoints={{
            880: {
              allowTouchMove: true,
            },
          }}
        // resizeObserver={false}
        // updateOnWindowResize={false}
        //onSlideChange={() => console.log("slide change")}
        //onSwiper={swiper => console.log(swiper)}
      >
        {video && (
          <SwiperSlide>
            {showVideo && <ReactPlayer
              url={video}
              // playsinline
              // loop
              muted={isIOS}
              controls
              playing={playing}
              onError={error => {
                setVidError(true)
                if(error)
                  console.log("error", error)
              }}
              width="100%"
              height="100%"
              playsinline
            />}
            {video_image && !playing && !isVidError && (
              <div>
                <div className="video-controls">
                  <div className="video-controls__bg" >
                    
                    <BgImage
                      image={video_image}
                      style={{ minWidth: 200, minHeight: 140 }}
                    >
                      <button type="button" onClick={() => playVideo()}>
                      Play
                    </button>

                      </BgImage>
                  </div>
                </div>
              </div>
            )}
            {isVidError && (
              <div className="video-controls">
                <p>
                  There seems to be an issue with a video player at the moment.
                  <br />
                  Keep scrolling the images.
                </p>
              </div>
            )}
          </SwiperSlide>
        )}

        {!video && video_image && publication && 
        <SwiperSlide>
          <div>
            <div className="publication-frame">
              <div className="publication-frame__bg">
                <BgImage
                  image={video_image}
                  style={{ minWidth: 200, minHeight: 140 }}
                >
                  <a className="button-link external" href={publication} target="_blank">Read</a>

                  </BgImage>
              </div>
            </div>
          </div>
        </SwiperSlide>
        }

        {bgimages && bgimages.sort((a, b) => a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1).map((slide, index) => renderSlide(slide, index))}

        {!bgimages && !video && !publication && renderSlide(" ", 1)}
      </Swiper>
    </div>
  )
}

export default MySwiper
