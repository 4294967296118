import React, {
  /*  useContext, useState, */ useRef,
  createRef,
  useEffect,
} from "react"
//import { Link } from "gatsby"
import MySwiper from "../components/swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Mousewheel, Keyboard, Navigation } from "swiper"

import "swiper/swiper.scss"

import MainContext from "../stores/mainContext"
import { useCurrentWidth } from "../components/getWidthHook"

import { gsap } from "gsap"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"

SwiperCore.use([Mousewheel,Navigation,Keyboard])

gsap.registerPlugin(ScrollToPlugin)
const isClient = typeof window === "object"

const Filter = ({ projects }) => {
  const { mainContext, dispatch } = React.useContext(MainContext)
  const [filteredProjects, setfilteredProjects] = React.useState(projects)
  const [isLoopSwiper, setIsLoopSwiper] = React.useState(true)

  useEffect(() => {
    dispatch({ type: "SET_PROJECTS", payload: projects })
  }, [])
  useEffect(() => {
    let newProjects = [...projects]

    if (mainContext.searchText.length > 0) {
      setIsLoopSwiper(false)
      newProjects = newProjects.filter(item => {
        return (
          item.title
          .toLowerCase()
          .indexOf(mainContext.searchText.toLowerCase()) > -1
          )
        })
        dispatch({ type: "SET_SEARCH_RESULT_COUNT", payload: newProjects.length })
        if (newProjects.length > 0) {
          let jumpTo =
          newProjects.length < projects.length
          ? newProjects[newProjects.length - 1]?.id
          : 1
          gsap.to(window, { duration: 1, scrollTo: jumpTo })
        }
      } else {
      setIsLoopSwiper(true)
      newProjects = [...projects]
    }
    setfilteredProjects(newProjects)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainContext.searchText, projects])

  useEffect(() => {
    const cat = mainContext.category
    let newProjects = [...projects]

    if (cat === null) {
      setfilteredProjects(newProjects)
    } else {
      const projectsByCat = newProjects.filter((s, i) => {
        if (s.category === cat) {
          return s
        }
      })
      const projectsByRandom = newProjects.filter((s, i) => {
        if (s.category !== cat) {
          return s
        }
      })
      const projectsSorted = projectsByCat.concat(projectsByRandom);
      setfilteredProjects(projectsSorted)
      swiperRef.current.swiper.slideToLoop(0,0)
    }
  }, [mainContext.category, projects])

  let currentWidth = useCurrentWidth()

  const elementsRef = useRef(filteredProjects.map(() => createRef()))
  const swiperRef = useRef()
  
  if (filteredProjects?.length > 0) {
    return (
      <Swiper
        ref={swiperRef}
        loop={isLoopSwiper}
        slidesPerView={"auto"}
        direction={"vertical"}
        loopedSlides={4}
        navigation={false}
        keyboard={{
          enabled: true,
        }}
        mousewheel={{
          thresholdTime: 1000,
        }}
        centeredSlides={true}
        spaceBetween={0}
        updateOnWindowResize={true}
        resizeObserver={true}
        onOrientationchange={swiper => setTimeout(() => swiper.update(), 250)}
        onSlideChange={swiper => {

          setTimeout(() => {

            const projectIndex = swiper.activeIndex
            const activeProjectCat = swiper.slides[projectIndex].dataset.cat
            const navLinks = isClient
              ? document.querySelectorAll(".navLink")
              : null
            for (let i = 0; i < navLinks.length; i++) {
              if (activeProjectCat === navLinks[i].innerHTML) {
                navLinks[i].classList.add("active")
              } else {
                navLinks[i].classList.remove("active")
              }
            }

          }, 300)

        }}
        onResize={swiper => setTimeout(() => swiper.update(), 250)}
      >
        {filteredProjects.map((item, index) => {
          if (item.pdf) {
            var newUrl = ""
            var url = item.pdf.localFile.url
            newUrl = url.replace(
              "https://directus.code8.link/uploads/grovesnatcheva/",
              "https://grovesnatcheva.code8.link/download/"
            )
            item.pdf.localFile.url = newUrl
          }
          const press = item.category === "press"
          {
            /* set loop to false if there's only 1 image */
          }
          const entries = Object.entries(item)
          const images = entries.filter((item, index) => {
            if (item[0].indexOf("image_") > -1) {
              return item
            }
          })
          {/* const isLoop = images.every(item => {
            return item[1] !== null
          }) */}
          const isLoop = Array.isArray(item?.files) && item?.files.length > 1

          const gap = currentWidth > 880 ? 32 : 16

          return (
            <SwiperSlide
              key={`project${index}`}
              ref={elementsRef.current[index]}
              id={item?.id}
              data-anchor={`id_${item?.id}`}
              data-cat={item?.category}
              className={`fp-section project ${item.category} ${
                mainContext.activeProject === item?.id ? "active" : ""
              }`}
            >
              <MySwiper
                key={`mySwiper${index}`}
                gap={gap}
                loop={isLoop}
                files={item?.files}
                publication={item?.publication_url}
                video={item?.video}
                video_image={item?.video_image?.localFile.childImageSharp.gatsbyImageData}
              />
              <div className="projectInfo">
                <h2>{item.title}</h2>
                <span className="category">
                  <span key={`category${item.category}`}>{item.category}</span>
                </span>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
    )
  } else {
    return (
      <div className="nothingFound">
        <br />
        <br />
        <br />
        <p>Your search haven't found any suitable matches.</p>
      </div>
    )
  }
}
export default Filter
